import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import AppButton from "../Button";
import logo from "../../Assets/images/logo.svg";
import linkedIn from '../../Assets/images/Linkedin.svg'
import { Link } from "react-scroll";

const AppNavBar = ({ back, hideNav = "true" }) => {
  return (
    <div className="app-navbar">
      <Navbar expand="lg">
        <Container >
          <Navbar.Brand href="#">
            <img src={logo} alt="Translator Logo"/>
          </Navbar.Brand>
          
          <AppButton className="mbBtn" title="REQUEST DEMO" />
          {hideNav && (
            <>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <hr></hr>
              <Navbar.Collapse id="navbarScroll">
                <Nav>
                  <Link className="nav-link" to="platform">
                    PLATFORM
                  </Link>
                  <Link className="nav-link" to="solution">
                    SOLUTION
                  </Link>
                  <Link className="nav-link" to="features">
                    FEATURES
                  </Link>
                  <Link className="nav-link" to="marketplace">
                     MARKETPLACE
                  </Link>
                  <Link className="nav-link" to="ourstory">
                    OUR STORY
                  </Link>
                  {/* <Link className="nav-link" to="contact">
                    CONTACT
                  </Link> */}
                  <div className="mobileNavFooter">
                    <Link to="contact">
                      <AppButton
                        title="REQUEST DEMO"
                        className="navbar-btn showonMobile"
                        color="#5C1783"
                        />
                        </Link>
                      <div className="connect">
                        <p>CONNECT WITH US</p>
                        <img src={linkedIn} alt="linkedIn"/>
                      </div>
                      <div className="content">
                        <a>Terms & Conditions</a>
                        <a>Privacy Policy</a>
                        <p>© {new Date().getFullYear()} Translator, Inc. All rights reserved</p>
                      </div>
                  </div>
                  {/* <Nav.Link href="#deets">PLATFORM</Nav.Link>
                  <Nav.Link href="#deets">SOLUTION</Nav.Link>
                  <Nav.Link href="#deets">FEATURES</Nav.Link>
                  <Nav.Link href="#deets">RESOURCES</Nav.Link>
                  <Nav.Link href="#deets">OUR STORY</Nav.Link>
                  <Nav.Link href="#deets">CONTACT</Nav.Link> */}
                </Nav>
                <Link to="contact">
                  <AppButton
                    title="REQUEST DEMO"
                    className="navbar-btn"
                    color="#5C1783"
                  />
                </Link>
              </Navbar.Collapse>
            </>
          )}
          {back && (
            <a href="#" className="back-link">
              Back to homepage
            </a>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavBar;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import styles from "./style.module.scss";
import tech from "../../Assets/images/tech.svg";
import impc from "../../Assets/images/impc.svg";
import dataAnalysis from "../../Assets/images/data.svg";
import timely from "../../Assets/images/timely.svg";
import onboard from "../../Assets/images/onboard.svg";
import participant from "../../Assets/images/participant.svg";

import liveLearning from '../../Assets/images/features/liveLearning.svg'
// import trainTheTrainer from '../../Assets/images/features/trainTheTrainer.png'
import trainTheTrainer from '../../Assets/images/features/traintheTrainer.svg'
import avatar from '../../Assets/images/features/avatar.svg'
import dataAnalitics from '../../Assets/images/features/data.svg'
import anonymous from '../../Assets/images/features/anonymous.svg'
import community from '../../Assets/images/features/community.svg'

import { HorizontalLine } from "../../Components/SmallElements";
import topImg from "../../Assets/images/featureHead_top.png";
import { FeaturesCard } from "../../Components/Cards";


const data = [
    {
      img: liveLearning,
      title: "Live Learning",
      desc: "Boosted program retention, increased empathy and stronger bonding among team members."
    },
    {
      img: trainTheTrainer,
      title: "Train-the-Trainer as a Service",
      desc: "Cost-efficient training, hassle-free scheduling, and enhanced engagement of star employees."
    },
    {
      img: avatar,
      title: "Avatar Co-Facilitation",
      desc: "Consistent curriculum deliver, reduced trainer burnout, and multi-language capability."
    },
    {
      img: dataAnalitics,
      title: "Rich Data & Analytics",
      desc: "ROI analysis and compliance with environmental, social, & governance standards."
    },
    {
      img: anonymous,
      title: "Anonymous Exercises",
      desc: "Enhance workshop participation and discover deep employee understanding without extra surveys."
    },
    {
      img: community,
      title: "Community of Practice",
      desc: "Access to a diverse global network of Translator facilitators for support and best practices."
    }
  ];


function Features() {
    return (
        <div id="features" className={styles.secFoureBox}>
        <div className={styles.secFoure}>
          <img src={topImg} width="100%" alt="features"/>
          <Container className={styles.container}>
            <p className={styles.title}>Features & Benefits</p>
            <HorizontalLine />
            <Row className={styles.cardRow}>
              {data.map(item => (
                <Col xs={6} sm={6} md={4} className={styles.cardSec} key={item.title}>
                  <FeaturesCard
                    title={item.title}
                    desc={item.desc}
                    img={item.img}
                  />
                </Col>
              ))}
            </Row>
            {/* <div className={styles.mbSlider}>
              <Carousel className={styles.slider} controls={false}>
                {data.map(item => (
                  <Carousel.Item
                    style={{
                      height: 320,
                      padding: "20px 15px"
                    }}
                    interval={3000}
                    key={item.title}
                  >
                    <FeaturesCard
                      title={item.title}
                      desc={item.desc}
                      img={item.img}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div> */}
          </Container>
        </div>
        {/* <img src={bottomImg} className={styles.newsTopImg} /> */}
      </div>
       
    )
  }

export default Features

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_icon_bttn__w6jUp {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 3px;\n  padding: 1rem 4rem;\n  height: 47px;\n  background-color: var(--color-primary);\n  cursor: pointer;\n  padding: 10px 25px; }\n  .style_icon_bttn__w6jUp img {\n    margin-left: 7px;\n    width: 14px; }\n  .style_icon_bttn__w6jUp .style_icon_bttn_p__K1c7I {\n    color: var(--color-white);\n    font-size: 16px;\n    text-transform: capitalize;\n    font-family: Montserrat700;\n    letter-spacing: 0.2px; }\n\n.style_icon_bttn__w6jUp:hover {\n  transform: translateY(-2px); }\n", "",{"version":3,"sources":["webpack://./src/Components/Button/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EAEZ,sCAAsC;EACtC,eAAe;EACf,kBAAkB,EAAA;EAVtB;IAYM,gBAAgB;IAChB,WAAW,EAAA;EAbjB;IAgBM,yBAAyB;IACzB,eAAe;IACf,0BAA0B;IAC1B,0BAA0B;IAC1B,qBAAqB,EAAA;;AAGzB;EACE,2BAA2B,EAAA","sourcesContent":[".icon_bttn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 3px;\n    padding: 1rem 4rem;\n    height: 47px;\n    // width: 100%;\n    background-color: var(--color-primary);\n    cursor: pointer;\n    padding: 10px 25px;\n    img{\n      margin-left: 7px;\n      width: 14px;\n    }\n    .icon_bttn_p {\n      color: var(--color-white);\n      font-size: 16px;\n      text-transform: capitalize;\n      font-family: Montserrat700;\n      letter-spacing: 0.2px;\n    }\n  }\n  .icon_bttn:hover {\n    transform: translateY(-2px);\n  }\n  \n\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon_bttn": "style_icon_bttn__w6jUp",
	"icon_bttn_p": "style_icon_bttn_p__K1c7I"
};
export default ___CSS_LOADER_EXPORT___;

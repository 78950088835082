import React, { useState } from 'react';
import axios from 'axios';
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import { CommonTitle } from "../../Components/SmallElements";

import { AppCheckbox, CommonTextArea, InputField } from "../../Components/Input";
import AppButton from "../../Components/Button";

import curveGbg from "../../Assets/images/curveGbg.svg";


function Contact() {

  const [firstName, setFirstName] = useState("");
  const [firstNameMissing, setFirstNameMissing] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameMissing, setLastNameMissing] = useState(false);
  const [email, setEmail] = useState("");
  const [emailMissing, setEmailMissing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameMissing, setCompanyNameMissing] = useState(false);
  const [jobTitle, setjobTitle] = useState("");
  const [message, setMessege] = useState("");
  const [partnership, setPartnership] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);

  const handleSubmit = async (e) => {
    if(!firstName){
      setFirstNameMissing(true)
    } else{
      setFirstNameMissing(false)
    }; 

    if(!lastName){
      setLastNameMissing(true)
    }else{
      setLastNameMissing(false)
    };
      
    if(!email){
      setEmailMissing(true)
    } else{
      setEmailMissing(false)
    }; 

    if(!companyName){
      setCompanyNameMissing(true)
    } else{
      setCompanyNameMissing(false)
    }; 
    // console.log(firstNameMissing)
    // console.log(lastNameMissing)
    // console.log(emailMissing)

    if(companyName || firstName || lastNameMissing || emailMissing) {
      const hubspot_response = await submit_hubspot_form(firstName, lastName, email , phoneNumber , companyName, jobTitle, message, partnership);
      if (hubspot_response) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setCompanyName("");
        setjobTitle("");
        setMessege("");
        setPartnership(false);
        setFormSubmited(true);
      }
    }
 

  }

  const submit_hubspot_form = async (firstName, lastName, email , phoneNumber , companyName, jobTitle, message, partnership) => {
    const portalId = process.env.REACT_APP_PORTAL_ID; 
    // console.log(firstName, lastName, email , phoneNumber , companyName, jobTitle, message, partnership)
    const formGuid = process.env.REACT_APP_FORM_GUID; 
    const config = { 
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'phone',
            value: phoneNumber,
          },
          {
            name: 'company',
            value: companyName,
          },    
          {
            name: 'jobtitle',
            value: jobTitle,
          },          
          {
            name: 'message',
            value: message,
          },
          {
            name: 'consultant_partnership_invester',
            value: partnership,
          }
        ],
      },
      config
    );
    
    // console.log(response)
    return response;
return 

    }

    return (

        <div id="contact" className={styles.contactSection}>
        <img src={curveGbg} className={styles.curveGbg}  alt="backgroud"/>
        <Container>
          <Row>
            <CommonTitle
              title="We’d be honored to meet you"
              fontSize="3.6rem"
              textAlign="center"
              margin=" 0 0 3.7rem 0"
              fontWeight="700"
              // color="var(--color-white)"
            />
            <Col lg={4} style={{marginBottom: '25px'}}>
              <InputField
                label="first name*"
                labelColor= "#fff"
                textTransform="uppercase"
                borderRadius="3px"
                border="1px solid #000"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
              <p style={{color:'#D2122E', display: firstNameMissing? '': 'none', fontSize: '14px'}}>Required</p>
            </Col>

            <Col lg={4} md={12} style={{marginBottom: '25px'}}>
              <InputField
                label="last name*"
                labelColor="#fff"
                textTransform="uppercase"
                borderRadius="3px"
                border="1px solid #000"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
              <p style={{color:'#D2122E', display: lastNameMissing? '': 'none', fontSize: '14px'}}>Required</p>
            </Col>
            <Col lg={4} style={{marginBottom: '25px'}}> 
              <InputField
                label="work email*"
                labelColor="#fff"
                textTransform="uppercase"
                borderRadius="3px"
                border="1px solid #000"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <p style={{color:'#D2122E', display: emailMissing? '': 'none', fontSize: '14px'}}>Required</p>
            </Col>
            <Col lg={4} style={{marginBottom: '25px'}}>
              <InputField
                label="phone number"
                labelColor="#fff"
                textTransform="uppercase"
                borderRadius="3px"
                border="1px solid #000"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </Col>
            <Col lg={4} style={{marginBottom: '25px'}}>
              <InputField
                label="company name*"
                labelColor="#fff"
                textTransform="uppercase"
                borderRadius="3px"
                border="1px solid #000"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
              <p style={{color:'#D2122E', display: companyNameMissing? '': 'none', fontSize: '14px'}}>Required</p>

            </Col>
            <Col lg={4} style={{marginBottom: '25px'}}>
              <InputField
                label="Job Title"
                labelColor="#fff"
                textTransform="uppercase"
                borderRadius="3px"
                border="1px solid #000"
                value={jobTitle}
                onChange={e => setjobTitle(e.target.value)}
              />
            </Col>
            <Col lg={12}>
              <CommonTextArea label="ADDITIONAL COMMENTS (OPTIONAL)" onChange={e => setMessege(e.target.value)} value={message}/>
            </Col>
            <Col lg={12}>
              <AppCheckbox
                lable="Check this box if you are DEI consultant or would like
                   to discuss investor, partnership, or PR opportunities."
                margin="25px 0 0 0"
                value={partnership}
                onChange={e => setPartnership(!partnership)}
              />
            </Col>

            <Col>

            {formSubmited? 
             <p className={styles.formCompleated}>Thanks for submitting the form. We're very excited to hear from you. </p>
              :
              <AppButton
                title="lets do this"
                backgroundColor="var(--color-bg-btn)"
                color="var(--color-black)"
                textTransform="uppercase"
                margin="30px 0 0 0 "
                className="contactBTN"
                // onClick={console.log('this is clicked')}
                onClick={() => handleSubmit()}
                value="Submit"
                type="submit"
              />

            }
            </Col>

          </Row>
        </Container>
      </div>
     
    )
  }

export default Contact

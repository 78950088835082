import classNames from 'classnames';
import React from 'react';
import styles from "./style.module.scss"

export const HorizontalLine = ({margin, className}) => {
  return (
    <div className={classNames(styles.line, className)} style={{margin}}/>
  );
}


export const CommonTitle = ({
  className,
  title,
  color,
  fontFamily,
  fontStyle,
  fontWeight,
  fontSize,
  lineHeight,
  margin,
  textAlign,
}) => {
  return (
    <h2 className={classNames( styles.title,className)}
      style={{
        color,
        fontFamily,
        fontSize,
        fontStyle,
        lineHeight,
        fontWeight,
        margin,
        textAlign,
      }}
    >
      {title}
    </h2>
  );
};


const Resources = [
  {
    id: 1,
    learningExperience: 'Cultivating Connection & Belonging',
    title: 'Calling In vs. Calling Out',
    description: 'How to log in, update user settings, and update your password.',
    linkUrl: 'https://s3.amazonaws.com/join.host.dashboard/Cultivating+Connection+_+Belonging/Translator+Resource_+Calling+In+vs.+Calling+Out.pdf',
    updatedAt: '2023-05-03T19:04:05.210Z',
  },
  {
    id: 2,
    learningExperience: 'Cultivating Connection & Belonging',
    title: 'Six Principles of Inclusive Language',
    description: 'How to log in, update user settings, and update your password.',
    linkUrl: 'https://s3.amazonaws.com/join.host.dashboard/Cultivating+Connection+_+Belonging/Translator+Resource_+Six+Principles+of+Inclusive+Language.pdf',
    updatedAt: '2023-05-03T19:04:05.210Z',
  },
  {
    id: 3,
    learningExperience: 'Dismantling Our Prejudices',
    title: 'DEED Model',
    description: 'How to log in, update user settings, and update your password.',
    linkUrl: 'https://s3.amazonaws.com/join.host.dashboard/Dismantling+Our+Prejudices/Translator+Resource_+DEED+Model.pdf',
    updatedAt: '2023-05-11T19:04:05.210Z',
  },
  {
    id: 4,
    learningExperience: 'Self-Awareness & Empathy',
    title: 'Demostrating Allyship',
    description: 'How to log in, update user settings, and update your password.',
    linkUrl: 'https://s3.amazonaws.com/join.host.dashboard/Self-Awareness+_+Empathy/Translator+Resource_+Demonstrating+Allyship+.pdf',
    updatedAt: '2023-05-11T19:04:05.210Z',
  },  
  {
    id: 5,
    learningExperience: 'General',
    title: 'DEI Resource List',
    description: 'How to log in, update user settings, and update your password.',
    linkUrl: 'https://s3.amazonaws.com/join.host.dashboard/Translator+DEI+Resource+List.pdf',
    updatedAt: '2023-05-11T19:04:05.210Z',
  },
];
const LearningExp =[
  {title: 'General'},
  {title: 'Cultivating Connection & Belonging'}, 
  {title:'Dismantling Our Prejudices'},
  {title: 'Self-Awareness & Empathy'},

]

// export default Resources

export {
  Resources, 
  LearningExp
}
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import bottomImg from "../../Assets/images/featureHead_bottom.png";
import { CommonTitle } from "../../Components/SmallElements";
import { NewsCard } from "../../Components/Cards";
import classNames from "classnames";

import AppSlider from "../../Components/Slider/Resources/resourceSlider"

const newsData = [
    {
      title: "Diversity, Equity & Inclusion",
      status: "LIVE",
      color: "#A0CEB7",
      fontColor: "black",
    },
    {
      title: "Financial Literacy",
      status: "BETA",
      color: "#E4C453",
      fontColor: "black",
    },
    {
      title: "Leadership Development",
      status: "COMING SOON!",
      color: "#5C1783",
      fontColor: "white",

    },   
    {
      title: "Performance Management",
      status: "COMING SOON!",
      color: "#5C1783",
      fontColor: "white",
    },    
    {
      title: "Talent Acquisition",
      status: "COMING SOON!",
      color: "#5C1783",
      fontColor: "white",
    },    
    {
      title: "Business Development",
      status: "COMING SOON!",
      color: "#5C1783",
      fontColor: "white",
    },  
    {
      title: "Customer Service",
      status: "COMING SOON!",
      color: "#5C1783",
      fontColor: "white",
    },    
    {
      title: "And many more!",
      status: "COMING SOON!",
      color: "#5C1783",
      fontColor: "white",
    },
 
  ];
  

function Resources() {
    return (
        <div id="marketplace" className={styles.newsSection}>
        <img src={bottomImg} width="100%" className={styles.newsTopImg} alt="" />
        <Container>
          <Row className={styles.newsRow}>
            <CommonTitle
              title="A global marketplace"
              color="var(--color-success)"
              fontSize="3.6rem"
              fontWeight="700"
              // textAlign="center"
            />
            <div className={styles.descArea}>
              <p className={styles.desc} style={{marginBottom: '20px'}}>
              For in-house Train-the-Trainer content, developed and delivered with a DEI lens.
              </p>            
              <p className={styles.desc}>
                Empower your employees to deliver trainings 24/7 without having to become a subject matter expert!
              </p>
            </div>
   
            <hr
              className={styles.hrLineForStore}
                style={{ border: "1.5px solid #214D49" , opacity: 1, zIndex: 1 }}
              />
            <div className={styles.forDesktop}>
              {newsData.map((item, index) => {
                return (
                    <Col sm={6} md={6} lg={4} xl={3} className={styles.newsCol} key={item.title}>
                      <NewsCard item={item} />
                    </Col>
                );
              })}
            </div>
            <div className={classNames(styles.forMobile,'newsSlider')}>
              <AppSlider data={newsData}/>
            </div>
          </Row>
        </Container>
      </div>

       
    )
  }

export default Resources

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Landing from "../../Pages/Landing";
import Terms from "../../Pages/Terms";
import Policy from "../../Pages/Policy";
import Resources from "../../Pages/Resources/Resources";



const AppRouting = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Landing/>} />
      <Route path="/terms-conditions" element={<Terms/>} />
      <Route path="/resources" element={<Resources/>} />
      <Route path="/privacy-policy" element={<Policy/>} />
    </Routes>
  </Router>
)
export default AppRouting;
import React from "react";
import AppNavBar from "../../Components/AppNav/CleanNav";
import {Container } from 'react-bootstrap';
import ResourceCard from './ResourceCard'
import {Resources, LearningExp} from './ResourceData';
import styles from "../../Pages/Landing/style.module.scss";
import { Footer } from "../../Components/Footer";

const sortByLearningExp = (lE, resource) => {
  if (resource.learningExperience.includes(lE.toString())) {
    return (
      <ResourceCard resource={resource}  key={resource.id}/>
    );
  }
  return null;
};


const Landing = () => {
  return (
    <Container>

    <div className={styles.resourceLanding}>
      <div>
      <AppNavBar />
      <div className={styles.resourceList}>
      {LearningExp.map((lE) => (
        <Container key={lE.title}>
          <div>
          <h1>
            {lE.title}
          </h1>
          { Resources.map((resource) => sortByLearningExp(lE.title, resource))}
          </div>
         
       </Container>

      ))}

      </div>
      </div>


        <div >
          <Footer />
        </div>
        
    </div>
    </Container>

  );
};

export default Landing;

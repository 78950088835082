import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import { CommonTitle } from "../../Components/SmallElements";
import { OurStoryCard } from "../../Components/Cards";
import founder from "../../Assets/images/founder1.png";
import coFounder from "../../Assets/images/coFounder1.png";

import storyLeftImg from "../../Assets/images/storyLeft.png";
import storyRightImg from "../../Assets/images/storyRight.png";

function OurStory() {
    return (
        <div id="ourstory" className={styles.OurStorySection}>
        <img src={storyLeftImg} className={styles.leftImg} alt="backgroup"/>
        <img src={storyRightImg} className={styles.rightImg} alt="backgroup" />
        <Container className={styles.contentContainer}>
          <Row>
            <Col className={styles.ourStoryCol}>
              <CommonTitle
                title="Our story: DEI is in our DNA™"
                fontSize="3.8rem"
                fontWeight="700"
                lineHeight="44px"
                color="#000"
              />
              {/* <HorizontalLine /> */}
              <hr
              className={styles.hrLineForStore}
                style={{ border: "1.5px solid #000" , opacity: 1, zIndex: 1 }}
              />
              <OurStoryCard
                title="Natalie J. Egan"
                imgvalue={founder}
                gender="(she / hers)"
                subTitle="CEO + Founder"
                desc="Translator was founded by serial tech entrepreneur Natalie Jane Egan after she
                      came out as a transgender woman in 2016 and experienced bias, discrimination, and hatred for
                      the first time in her life. With 20+ years experience working in B2B digital transformation
                      Natalie had a vision to scale empathy through technology and to make DEI data-driven."
              />

              <OurStoryCard
                title="Joshua Torres"
                imgvalue={coFounder}
                gender="(he / his)"
                subTitle="CTO + Co-Founder"
                desc="After two years of R&D including hundreds of hours of interviews and consulting with
                    Chief Diversity Officers, DEI trainers, HR leaders, and People Scientists, Natalie joined
                    forces with Josh Torres as her co-founder and Chief Technical Officer in 2018
                    to launch Translator, Inc. as it is known today."
              />

              <p className={styles.lastPara}>
                Together, the pair represents a truly unique perspective in tech
                as less than 7% of all venture capital backed CTO’s are hispanic
                / latine and Natalie is one of only 10 known, openly
                transgender, venture capital backed tech CEO’s in the world. For
                more information, public relations, speaking, and investor
                inquiries please contact us below.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

       
    )
  }

export default OurStory

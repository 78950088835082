// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_textAreaCustom__Xtfj- {\n  border: solid 1px #000;\n  font-size: 14px;\n  border-radius: 3px;\n  padding: 16px 2rem; }\n\n.style_textAreaCustom__Xtfj-:focus {\n  border: solid 1px #000;\n  box-shadow: none; }\n\n.style_textAreaBox__-devi label {\n  font-size: 14px;\n  font-weight: 700;\n  color: var(--color-white);\n  text-transform: uppercase; }\n", "",{"version":3,"sources":["webpack://./src/Components/Input/style.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,kBAAkB,EAAA;;AAGtB;EACI,sBAAsB;EACtB,gBAAgB,EAAA;;AAEpB;EAEQ,eAAe;EACf,gBAAgB;EACpB,yBAAyB;EACzB,yBAAyB,EAAA","sourcesContent":[".textAreaCustom{\n    border: solid 1px #000;\n    font-size: 14px;\n    border-radius: 3px;\n    padding: 16px 2rem;\n    \n}\n.textAreaCustom:focus{\n    border: solid 1px #000;\n    box-shadow: none;\n}\n.textAreaBox{\n    label{\n        font-size: 14px;\n        font-weight: 700;\n    color: var(--color-white);\n    text-transform: uppercase;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textAreaCustom": "style_textAreaCustom__Xtfj-",
	"textAreaBox": "style_textAreaBox__-devi"
};
export default ___CSS_LOADER_EXPORT___;

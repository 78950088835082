import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import { CommonTitle } from "../../Components/SmallElements";
import { AppSlider } from "../../Components/Slider";
import ReactPlayer from 'react-player';


function Solution() {
    return (
      <Container id="solution" className={styles.secThree}>
      <Row>
        <Col xs={{ span: 12, order: 2 }} lg={{ span: 5, order: 1 }} xl={{ span: 6, order: 1 }}>
          <div className={styles.content}>
            <CommonTitle
              color="var(--color-success)"
              title="How does Translator work?"
            />
            <p className={styles.desc}>
            Translator combines cutting-edge digital co-facilitation technology with pre-built 
            workshops and live people-first conversations to help you measure and improve training 
            outcomes.
            </p>
            {/* <p className={styles.subDesc}>Watch this short video to learn more.</p> */}
          </div>
        </Col>
        <Col xs={{ span: 12, order: 1 }} lg={{ span: 7, order: 2 }} xl={{ span: 6, order: 1 }}>
          <div className={styles.aboutVideo}>
            <ReactPlayer
              url="https://translator-website.s3.amazonaws.com/aboutVideo2.mp4"
              width="100%"
              height="100%"
              style= {{display: "flex"}}
              controls
            />
          </div>
        </Col>
      </Row>
      <div className={styles.secThreeSlider}>
        <p className={styles.label}>PEOPLE ARE TALKING ABOUT US</p>
        <Row className={styles.sponserRow}>
          <AppSlider />
        </Row>
      </div>
      <div className={styles.mbSlider}>
        <AppSlider />
      </div>
    </Container>

       
    )
  }

export default Solution

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_line__gt7BV {\n  height: 4px;\n  width: 100%;\n  background-color: var(--color-white); }\n  @media screen and (max-width: 527px) {\n    .style_line__gt7BV {\n      width: 100%;\n      margin-left: 15px;\n      width: calc(100% + 15px); } }\n\n.style_title__7XLO9 {\n  font-size: 3.6rem;\n  font-family: Montserrat700;\n  color: var(--color-white); }\n  @media screen and (max-width: 527px) {\n    .style_title__7XLO9 {\n      font-size: 21px; } }\n", "",{"version":3,"sources":["webpack://./src/Components/SmallElements/style.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,WAAW;EACX,oCAAoC,EAAA;EACpC;IAJJ;MAKQ,WAAW;MACX,iBAAiB;MACjB,wBAAwB,EAAA,EAE/B;;AAED;EACI,iBAAiB;EACjB,0BAA0B;EAC1B,yBAAyB,EAAA;EACzB;IAJJ;MAKQ,eAAe,EAAA,EAEtB","sourcesContent":[".line{\n    height: 4px;\n    width: 100%;\n    background-color: var(--color-white);\n    @media screen and (max-width: 527px) {\n        width: 100%;\n        margin-left: 15px;\n        width: calc(100% + 15px);\n    }\n}\n\n.title{\n    font-size: 3.6rem;\n    font-family: Montserrat700;\n    color: var(--color-white);\n    @media screen and (max-width: 527px) {\n        font-size: 21px;\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": "style_line__gt7BV",
	"title": "style_title__7XLO9"
};
export default ___CSS_LOADER_EXPORT___;

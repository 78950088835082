import classNames from 'classnames'
import React from 'react'
import styles from './style.module.scss'

const AppButton = ({
  height,
  width,
  padding,
  backgroundColor,
  color,
  borderRadius,
  fontSize,
  title,
  src = null,
  imgWidth,
  margin,
  onClick,
  className,
  textTransform
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        height,
        width,
        backgroundColor,
        borderRadius,
        margin,
        padding,
        color,
        fontSize,
      
      }}
      className={classNames(styles.icon_bttn, className)}
    >
      <p
        style={{ color, fontSize,  textTransform, }}
        className={styles.icon_bttn_p}
        >
        {title}
      </p>
        {src && <img width={imgWidth} src={src} alt="button" />}
    </div>
  )
}

export default AppButton;
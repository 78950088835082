import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import { Link} from "react-scroll";
import { HeaderSlider } from "../../Components/Slider";
import AppButton from "../../Components/Button";
import headerImage from "../../Assets/images/HeaderImage.png";


function Header() {
    return (
        <Container>
        <div className={styles.header}>
          <Row className={styles.row}>
            <Col xs={12} md={12} lg={6}>
              <div className={styles.content}>
                <p className={styles.title}>
                  Tired of lonely learning?
                </p>
                <div className={styles.hMd}>
                <Link to="contact">    
                  <AppButton
                    className={styles.headBtn}
                    title="Try Translator!"
                  />
                  </Link>
                  <p className={styles.label}>The Live Learning Platform <br />with <span className={styles.labelSpan}>Train-the-Trainer as a Service</span></p>
                  <Row className={styles.sponserRow}>
                    <HeaderSlider />
                  </Row>
                </div>
              </div>
            </Col>
            <Col xs={12} md={12} lg={6} className={styles.imgSec}>
              <img src={headerImage} className={styles.banner} alt="vision of platform"/>
              <div className={styles.mbSec}>
              <Link to="contact">
                <AppButton className={styles.headBtn} title="Try Translator!"  />
                <p className={styles.label}>The Live Learning Platform <br />with <span className={styles.labelSpan}>Train-the-Trainer as a Service</span></p>
                </Link>

              </div>
              <Row className={styles.sponserRowMobile}>
                <h3 style={{marginTop: '70px', marginBottom: '-20px'}}>YOU'RE IN GOOD COMPANY</h3>
                    <HeaderSlider />
                  </Row>
              {/* <img src={mobile} className={styles.bgMbl} alt="phone"/> */}
            </Col>
          </Row>
        </div>
      </Container>

       
    )
  }

export default Header

import React from "react";
import Slider from "react-slick";
import headerSlider1 from "../../Assets/images/sponsers/ClairesLogo.svg";
import headerSlider2 from "../../Assets/images/sponsers/ParamountLogo.svg";
// import headerSlider3 from "../../Assets/images/sponsers/PNCLogo.svg";
import headerSlider4 from "../../Assets/images/sponsers/SpencerStuartLogo.svg";
import headerSlider5 from "../../Assets/images/sponsers/LuluLogo.svg";
import headerSlider6 from "../../Assets/images/sponsers/AmgenLogo.svg";
import headerSlider7 from "../../Assets/images/sponsers/PretiumLogo.svg";
import headerSlider8 from "../../Assets/images/sponsers/EALogo.svg";
import headerSlider9 from "../../Assets/images/sponsers/EnergageLogo.svg";
import headerSlider10 from "../../Assets/images/sponsers/FerraraLogo.svg";
import headerSlider11 from "../../Assets/images/sponsers/UniversityOfPennLogo.svg";
import headerSlider12 from "../../Assets/images/sponsers/WSDLogo.svg";
import headerSlider14 from "../../Assets/images/sponsers/DeputyLogo.svg";
import headerSlider15 from "../../Assets/images/sponsers/FoundLogo.svg";
import headerSlider16 from "../../Assets/images/sponsers/ModernMeadowLogo.svg";


import secSlider17 from "../../Assets/images/sponsers/ABCLogo.svg";
import secSlider18 from "../../Assets/images/sponsers/WSJLogo.svg";
import secSlider19 from "../../Assets/images/sponsers/InsiderLogo.svg";
import secSlider20 from "../../Assets/images/sponsers/FastCoLogo.svg";
import secSlider21 from "../../Assets/images/sponsers/ELLELogo.svg";
import secSlider22 from "../../Assets/images/sponsers/FortuneLogo.svg";
import secSlider23 from "../../Assets/images/sponsers/TheTimesLogo.svg";
import secSlider24 from "../../Assets/images/sponsers/CBSLogo.svg";
import secSlider25 from "../../Assets/images/sponsers/sxswLogo.svg";
// import secSlider26 from "../../Assets/images/sponsers/MercerLogo.svg";
import secSlider27 from "../../Assets/images/sponsers/RedThreadLogo.svg";
import secSlider28 from "../../Assets/images/sponsers/ThriveLogo.svg";
import secSlider29 from "../../Assets/images/sponsers/PowerToFlyLogo.svg";
import secSlider30 from "../../Assets/images/sponsers/WorkologyLogo.svg";
import secSlider31 from "../../Assets/images/sponsers/HRGazetteLogo.svg";
import secSlider32 from "../../Assets/images/sponsers/DellLogo.svg";
import secSlider33 from "../../Assets/images/sponsers/AtlassianLogo.svg";
import secSlider34 from "../../Assets/images/sponsers/ThisWayLogo.svg";
import secSlider35 from "../../Assets/images/sponsers/UnTappedLogo.svg";
import secSlider36 from "../../Assets/images/sponsers/AllvoicesLogo.svg";
import secSlider37 from "../../Assets/images/sponsers/LlunaLogo.svg";
import secSlider38 from "../../Assets/images/sponsers/TechnicallyLogo.svg";
import secSlider39 from "../../Assets/images/sponsers/WorkhumanLogo.svg";


export const AppSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
   
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 527,
        settings: {
          // speed: 2000,
          // autoplaySpeed: 2000,
          // cssEase: "linear",
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <img src={secSlider17} alt="ABC Logo"/>
        </div>
        <div>
          <img src={secSlider18} alt="WSJ Logo"/>
        </div>
        <div>
          <img src={secSlider19} alt="Insider Logo"/>
        </div>
        <div>
          <img src={secSlider20} alt="FastCo Logo"/>
        </div>
        <div>
          <img src={secSlider21} alt="ELLE Logo"/>
        </div>
        <div>
          <img src={secSlider22} alt="Fortune Logo"/>
        </div>
        <div>
          <img src={secSlider23} alt="The Times Logo"/>
        </div>
        <div>
          <img src={secSlider24} alt="CBS Logo"/>
        </div>
        <div>
          <img src={secSlider25} alt="sxsw Logo"/>
        </div>
        {/* <div>
          <img src={secSlider26} alt="Mercer Logo"/>
        </div> */}
        <div>
          <img src={secSlider27} alt="RedThread Logo"/>
        </div>
        <div>
          <img src={secSlider28} alt="Thrive Logo"/>
        </div>
        <div>
          <img src={secSlider29} alt="PowerToFly Logo"/>
        </div>
        <div>
          <img src={secSlider30} alt="Workology Logo"/>
        </div>
        <div>
          <img src={secSlider31} alt="HRGazette Logo"/>
        </div>
        <div>
          <img src={secSlider32} alt="Dell Logo"/>
        </div>
        <div>
          <img src={secSlider33} alt="Atlassian Logo"/>
        </div>
        <div>
          <img src={secSlider34} alt="ThisWay Logo"/>
        </div>
        <div>
          <img src={secSlider35} alt="UnTapped Logo"/>
        </div>
        <div>
          <img src={secSlider36} alt="Allvoices Logo"/>
        </div>
        <div>
          <img src={secSlider37} alt="Lluna Logo"/>
        </div>
        <div>
          <img src={secSlider38} alt="Technically Logo"/>
        </div>
        <div>
          <img src={secSlider39} alt="Workhuman Logo"/>
        </div>
      </Slider>
    </div>
  );
};

export const HeaderSlider = () => {
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 527,
        settings: {
          // speed: 10,
          // autoplaySpeed: 10,
          // cssEase: "linear",
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
  return (
    <div>
      <Slider {...settings2}>
        <div >
          <img src={headerSlider1} alt="Claires Logo"/>
        </div>
        <div>
          <img src={headerSlider2} alt="Paramount Logo"/>
        </div>
        {/* <div>
          <img src={headerSlider3} alt="PNC Logo"/>
        </div> */}
        <div>
          <img src={headerSlider4} alt="Spencer Stuart Logo"/>
        </div>
        <div>
          <img src={headerSlider5} alt="Lulu Logo"/>
        </div>
        <div>
          <img src={headerSlider6} alt="Amgen Logo"/>
        </div>
        <div>
          <img src={headerSlider7} alt="Pretium Logo"/>
        </div>
        <div>
          <img src={headerSlider8} alt="EA Logo"/>
        </div>
        <div>
          <img src={headerSlider9} alt="Energage Logo"/>
        </div>
        <div>
          <img src={headerSlider10} alt="Ferrara Logo"/>
        </div>
        <div>
          <img src={headerSlider11} alt="UniversityOfPenn Logo"/>
        </div>
        <div>
          <img src={headerSlider12} alt="WSD Logo"/>
        </div>
        <div>
          <img src={headerSlider14} alt="Deputy Logo"/>
        </div>
        
        <div>
          <img src={headerSlider15} alt="Found Logo"/>
        </div>
        <div>
          <img src={headerSlider16} alt="Modern Meadow Logo"/>
        </div>
       
      </Slider>
    </div>
  );
};

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './style.module.scss'
import linkedIn from '../../Assets/images/Linkedin.svg'

export const Footer = () => {
return(
    <div className={styles.footer}>
        <Container>
            <Row>
                <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} lg='6'>
                <div className={styles.content}>
                    <a href='/terms-conditions'>Terms & Conditions</a>
                    <a href='privacy-policy'>Privacy Policy</a>
                    <p>© {new Date().getFullYear()} Translator, Inc. All rights reserved</p>
            </div>
                </Col>
                <Col  xs={{ span: 12, order: 1 }} md={{ span:4, order: 2 }} lg='6' className={styles.connectBox}>
                <div className={styles.connect}>
                    <p>CONNECT WITH US</p>
                    <a href='https://www.linkedin.com/company/translator-inc'><img src={linkedIn} alt="linkedIn"/></a>
                </div>
                </Col>
            </Row>
          
        </Container>


    </div>
)
}   

import React from "react";
import AppNavBar from "../../Components/AppNav";
import styles from "./style.module.scss";



import { Footer } from "../../Components/Footer";

import Header from './Header'
import Platform from './Platform';
import Features from './Features';
import Solution from './Solution';
import Resources from './Resources';
import OurStory from './OurStory';
import Contact from './Contact';


const Landing = () => {
  return (
    <div className={styles.landing}>
        <AppNavBar />
        <Header />
        <Platform />
        <Solution />     
        <Features /> 
        <Resources />
        <OurStory />
        <Contact />
        <Footer />
    </div>
  );
};

export default Landing;

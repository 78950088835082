import React from "react";
import styles from "./style.module.scss";
import { CommonTitle } from "../SmallElements";

export const FeaturesCard = ({ img, title, desc }) => {
  return (
    <div className={styles.featureCard}>
      <div className={styles.imgSec}>
        <img src={img} alt={title}/>
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.desc}>{desc}</p>
    </div>
  );
};

export const OurStoryCard = ({ title, gender, subTitle, desc, imgvalue }) => {
  return (
    <div className={styles.storyCardContainer}>
      <div className={styles.imageDiv}><img src={imgvalue} alt={title}/></div>
      <div className={styles.contentDiv}>
        <div className={styles.titleDiv}>
          <CommonTitle
            title={title}
            fontSize="2.1rem"
            fontWeight="600"
            color="var(--color-dark)"
          />
          <span>{gender}</span>
        </div>
        <CommonTitle
          title={subTitle}
          color="var(--color-success-dark)"
          fontSize="2.6rem"
          fontFamily="Montserrat400"
          margin="0 0 1.7rem 0"
          fontWeight="600"
          
        />
        <p style={{textAlign: 'left'}}>{desc}</p>
      </div>
    </div>
  );
};

export const NewsCard = ({ item }) => {
  return (
    <div className={styles.newsCarContainer}>
      <div>
      <CommonTitle
        title={item.title}
        // fontSize="2.4rem"
        // margin="0px 0px 10px 0px"
        // color={item.color}
        className={styles.newsTitle}
      />
      </div>
      
      <div className={styles.tag} style={{background: item.color, color: item.fontColor}}>
        {item.status}
      </div>
    </div>
  );
};

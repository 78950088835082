import React from "react";
import { Form } from "react-bootstrap";
import styles from './style.module.scss'
export const InputField = ({
    label,
    type,
    placholder,
    margin,
    optional,
    as,
    height,
    borderRadius,
    border,
    onChange,
    value
}) => {
    return (
        <div className="form-group" style={{ margin  }}>
            <Form.Label>{label}</Form.Label>
            <Form.Control onChange={onChange} type={type} value={value} placeholder={placholder} as={as} style={{ height,borderRadius,border }} />
            <p className="optional">{optional}</p>
        </div>
    );
}

export const CommonTextArea = (
    {
        title,
        marginTop,
        rows,
        label,
        value,
        onChange
    }
) => {
    return (
        <div className={styles.textAreaBox}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as="textarea"
                value={value}
                onChange={onChange}
                placeholder={title}
                className={styles.textAreaCustom}  rows={rows="10"} style={{marginTop}}
            />
        </div>
    )
}

export const SelectDD = ({ margin, lable }) => {
    return (
        <div className="selectdd" style={{ margin }}>
            <p className="lable">{lable}</p>
            <Form.Select aria-label="Default select example">
                <option>Select</option>
                <option value="1">Less than 10</option>
                <option value="2">10 to 20</option>
                <option value="3">more than 20</option>
            </Form.Select>
        </div>
    );
}
export const AppCheckbox = ({ margin, lable, value, onChange }) => {
    return (
        <div className="check-container" style={{ margin }}>
            <input className="app-checkbox" type="checkbox" checked={value} onChange={onChange}/>
            <span className="checklable">{lable}</span>
        </div>
    );
}

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import styles from "./style.module.scss";
import wtBanner from "../../Assets/images/secBanner1.png";
import AppButton from "../../Components/Button";
import { Link} from "react-scroll";

import { HorizontalLine } from "../../Components/SmallElements";


function Platform() {
    return (

        <div id="platform" className={styles.secTwo}>
        <Container className={styles.container}>
          <Row className={styles.wtRow}>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <img src={wtBanner} className={styles.wtBanner} alt="banner"/>
            </Col>
            <Col className={styles.contentBox} xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={5}>
              <div className={styles.content}>
                <p className={styles.title}>Need to do more with less?</p>
                <HorizontalLine className={styles.hLine} />
                <p className={styles.desc}>
                Empower your employees to facilitate live training sessions without having to become a subject matter expert.
                </p>
                <p className={styles.desc}>
                We help our customers:
                </p>
                <p className={styles.desc}>
                1. Optimize training costs <br />
                2. Improve workplace culture <br />
                3. Measure the results <br />
                </p>
                <Link to="contact">
                  <AppButton className={styles.tryBtn} title="Try Translator!" />
                </Link>
              </div>
              
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6}>
              <div className={styles.wtSlider}>
                <Carousel
                  controls={false}
                  interval={4000}
                  slide={true}
                  touch={true}
                  wrap={true}
                >
                  <Carousel.Item className={styles.cItem}>
                    <Carousel.Caption className={styles.cCaption}>
                      <h3>
                        “I am so grateful to my company for making this program
                        part of our culture.”
                      </h3>
                      <p>~ USER @PARAMOUNTGLOBAL</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  {/* <Carousel.Item className={styles.cItem}>
                    <Carousel.Caption>
                      <h3>“I want my whole company to do this!”</h3>
                      <p>~ USER @Ingredion</p>
                    </Carousel.Caption>
                  </Carousel.Item> */}
                  <Carousel.Item className={styles.cItem}>
                    <Carousel.Caption>
                      <h3>
                        “I love the how the technology creates a safe space to
                        learn and grow without judgement or fear.”
                      </h3>
                      <p>~ USER @PNCBANK</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item className={styles.cItem}>
                    <Carousel.Caption>
                      <h3>
                        “I will highly recommend Translator to all of our
                        partners.”
                      </h3>
                      <p>~ USER @SPENCER STUART</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <img src={wtWaterBg} className={styles.wtWaterBg} /> */}
      </div>
   
    )
  }

export default Platform

import React from "react";
import { Navbar, Container } from "react-bootstrap";
import logo from "../../Assets/images/logo.svg";

const AppNavBar = ({ back, hideNav = "true" }) => {
  return (
    <div className="app-navbar">
      <Navbar expand="lg">
        <Container >
          <Navbar.Brand href="/">
            <img src={logo} alt="Translator Logo"/>
          </Navbar.Brand>
        
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavBar;

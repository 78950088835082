import React from "react";
import Slider from "react-slick";
import { NewsCard } from "../../../Components/Cards";




const AppSlider = (props) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
   
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 527,
        settings: {
          // speed: 2000,
          // autoplaySpeed: 2000,
          // cssEase: "linear",
          slidesToShow: 1.1,
          centerMode: true,

          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
  return (
      <Slider {...settings}>
        {props.data.map((item)=>{
         return <NewsCard item={item} key={item.title}/>


        })}
        
      </Slider>
  );
};

export default AppSlider;
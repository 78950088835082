import React from 'react';
import { Col, Container, Row, Card, Button} from 'react-bootstrap';
import styles from '../../Pages/Landing/style.module.scss'

const ResourceCard = (props) => {
  const { resource } = props;
return(
    <div>
        <Container key={resource.id}>
            <Row>
                <Col sm="6" style={{marginBottom: '10px'}}>
                    <Card body>
                        <div className={styles.resourceLandingCard}>
                        <h3>{resource.title}</h3>

                        <a href={resource.linkUrl} download="MyExampleDoc" target='_blank'  rel="noreferrer"> 
                        <Button color="primary">
                            Download
                        </Button>
</a>
                        
                        </div>
                    
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
)
}   
export default ResourceCard